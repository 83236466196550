





































import { Component, Prop, Vue } from 'vue-property-decorator';
import ContactUsForm from '@/components/ContactUs.vue';
import CompanyDetail from '@/components/CompanyDetail.vue';
import ViewTitle from '@/components/ViewTitle.vue';

export default Vue.extend({
    components: {
        ContactUsForm,
        CompanyDetail,
        ViewTitle,
    },
});
